import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EstimateOptionalType, toArray } from '@/helpers/optionals';
import useOptionals from '@/hooks/useOptionals';
import { api, local } from '@/pages/api/api';
import { VehicleType, DepotType, OptionalType } from '@/types';
import { useSettings } from './settings';
import { Estimate, Estimates } from '@/types/coral-types';

export type ItineraryType = {
  pickup_store: string;
  return_store: string;
  pickup_date: string;
  return_date: string;
  pickup_time: string;
  return_time: string;
};

type RateType = {
  rate_id: string;
  vehicle_rate: string;
  vehicle_class: string;
};

interface ReservationContextInterface {
  itinerary?: ItineraryType;
  setItinerary: (itinerary: ItineraryType) => void;
  rate?: any;
  optionals?: EstimateOptionalType[];
  renter?: any;
  estimate?: Estimate;
  estimates: Estimates;
  setEstimates: React.Dispatch<React.SetStateAction<Estimates>>;
  depot?: DepotType;
  returnDepot?: DepotType;
  vehicle?: VehicleType;
  isPrePaid: boolean;
  paymentMethod: 'pix' | 'credit_card' | 'pos_paid';
  reservation: any;
  setReservation: any;
  setPaymentMethod: Function;
  user: any;
  setUser: any;
  reset: () => void;
  cpdid: string;
  allOptionals: OptionalType[];
  setAllOptionals: (allOptionals: OptionalType[]) => void;
  loadReservationData: (data: {
    estimate?: Estimate;
    depot?: DepotType;
    returnDepot?: DepotType;
    vehicle?: VehicleType;
    paymentMethod?: 'pix' | 'credit_card' | 'pos_paid';
    optionals?: EstimateOptionalType[];
    itinerary?: ItineraryType;
    reservation?: any;
    user?: any;
    allOptionals?: OptionalType[];
  }) => void;
  revalidateEstimate: () => Promise<void>;
}

export const CDPID_DATA = {
  pos_paid: "WEBXML",
  credit_card: "FOCPRE",
  pix: "FOCPIX"
}

const Context = createContext<ReservationContextInterface>(
  {} as ReservationContextInterface,
);

export function ReservationProvider({ children }: PropsWithChildren) {
  const router = useRouter();
  const { query, isReady } = router;
  const { pixDiscount, creditCardDiscount, isReady: isSettingsReady, pagamentoPadrao } = useSettings();
  const [itineraryReservation, setItinerary] = useState<ItineraryType | undefined>();

  const fromQuery = useCallback(
    (fields: string[]) => {
      return fields.reduce((obj, key) => ({ ...obj, [key]: query[key] }), {});
    },
    [query],
  );

  const itinerary = useMemo<ItineraryType | undefined>(() => {
    if (itineraryReservation) {
      return itineraryReservation
    }

    if (query) {
      return fromQuery([
        'pickup_store',
        'return_store',
        'pickup_date',
        'return_date',
        'pickup_time',
        'return_time',
      ]) as ItineraryType;
    }
  }, [query, itineraryReservation]);

  const rate = useMemo<RateType | undefined>(() => {
    if (query) {
      return fromQuery(['rate_id', 'vehicle_rate', 'vehicle_class']) as RateType;
    }
  }, [query]);

  const cpdid = useMemo<string>(() => {
    if (query.cdp_id) {
      return query.cdp_id as string;
    }
    return 'WEBXML';
  }, [query]);

  const { opts } = useOptionals({ enabled: !!query.extras });

  const [renter, setRenter] = useState();
  const [optionals, setOptionals] = useState<EstimateOptionalType[]>();
  const [estimate, setEstimate] = useState<Estimate | undefined>();
  const [estimates, setEstimates] = useState<Estimates>({
    pix: {} as Estimate,
    cartao: {} as Estimate,
    pospago: {} as Estimate,
  });
  const [depot, setDepot] = useState<DepotType | undefined>();
  const [returnDepot, setReturnDepot] = useState<DepotType | undefined>();
  const [vehicle, setVehicle] = useState<VehicleType | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<'pix' | 'credit_card' | 'pos_paid'>('credit_card');
  const [reservation, setReservation] = useState({});
  const [user, setUser] = useState({});
  const [allOptionals, setAllOptionals] = useState<OptionalType[]>([]);

  const isPrePaid = useMemo(
    () => paymentMethod === 'pix' || paymentMethod === 'credit_card',
    [paymentMethod],
  );

  const getEstimates = useCallback(async () => {
    const data = {
      pickup_store: query.pickup_store,
      return_store: query.return_store,
      pickup_datetime: `${query.pickup_date}T${query.pickup_time}:00`,
      return_datetime: `${query.return_date}T${query.return_time}:00`,
      class_code: query.vehicle_class,
      promo_code: query.coupon,
      options: [] as EstimateOptionalType[],
      rate_ids: {
        pospago: query.rate_pospago,
        pix: query.rate_pix,
        cartao: query.rate_cartao,
      },
      discounts: {
        pospago: "0",
        pix: String(pixDiscount),
        cartao: String(creditCardDiscount),
      },
    };

    if (query.extras) {
      data.options = toArray(
        Array.isArray(query.extras) ? query.extras : [query.extras],
      );
    }

    if (query.vehicle_class && isSettingsReady)
      try {
        const response = await local.post('/api/estimates', data);
        let fetchedEstimates: Estimates = {
          pix: response.data.estimates.pix,
          cartao: response.data.estimates.cartao,
          pospago: response.data.estimates.pospago,
        };
        fetchedEstimates.pospago = applyAdjustments(fetchedEstimates.pospago);
        fetchedEstimates.pix = applyAdjustments(fetchedEstimates.pix);
        fetchedEstimates.cartao = applyAdjustments(fetchedEstimates.cartao);
        setEstimates(fetchedEstimates);
      } catch (error) {
        console.error('Erro ao obter estimativas:', error);
      }
  }, [
    query.pickup_store,
    query.return_store,
    query.pickup_date,
    query.pickup_time,
    query.return_date,
    query.return_time,
    query.vehicle_class,
    query.coupon,
    query.rate_pospago,
    query.rate_pix,
    query.rate_cartao,
    query.extras,
    paymentMethod,
    pixDiscount,
    creditCardDiscount,
    isSettingsReady,
  ]);

  useEffect(() => {
    if (isReady) {
      getEstimates();
    }
  }, [isReady, getEstimates]);

  useEffect(() => {
    if (estimates && paymentMethod) {
      const selectedEstimate =
        paymentMethod === 'pix'
          ? estimates.pix
          : paymentMethod === 'credit_card'
            ? estimates.cartao
            : estimates.pospago;
      setEstimate(selectedEstimate);
    }
  }, [estimates, paymentMethod]);

  useEffect(() => {
    if (query.extras && opts) {
      const aux = (fromQuery(['extras']) as any).extras;
      const extras = toArray(Array.isArray(aux) ? aux : [aux]);

      extras.forEach(e => {
        e.optional = opts.find(o => o.coral_code === e.code);
      });
      setOptionals(extras);
    } else if (typeof optionals !== 'undefined') {
      setOptionals(undefined);
    }
  }, [query.extras, opts, fromQuery]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: depots } = await api(
        `/depots?coral_code=${query.pickup_store}`,
      );
      setDepot(depots[0]);
    };
    if (query.pickup_store) fetchData();
  }, [query.pickup_store]);

  useEffect(() => {
    const fetchReturnDepot = async () => {
      if (query.return_store) {
        const { data: depots } = await api(
          `/depots?coral_code=${query.return_store}`,
        );
        setReturnDepot(depots[0]);
      }
    };
    if (query.pickup_store === query.return_store) {
      setReturnDepot(depot);
    } else {
      fetchReturnDepot();
    }
  }, [query.return_store, query.pickup_store, depot]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: vehicles } = await api(
        `/vehicles?${new URLSearchParams({ group: query.vehicle_class as any })}`,
      );
      setVehicle(vehicles[0]);
    };
    if (query.vehicle_class) {
      fetchData();
    }
  }, [query.vehicle_class]);

  useEffect(() => {
    if (isSettingsReady) {
      if (pagamentoPadrao === 'cartao') {
        setPaymentMethod('credit_card');
      } else if (pagamentoPadrao === 'pix') {
        setPaymentMethod('pix');
      } else if (pagamentoPadrao === 'posPago') {
        setPaymentMethod('pos_paid');
      } else {
        setPaymentMethod('credit_card');
      }
    }
  }, [isSettingsReady, pagamentoPadrao]);

  const loadReservationData = useCallback((data: {
    estimate?: Estimate;
    depot?: DepotType;
    returnDepot?: DepotType;
    vehicle?: VehicleType;
    paymentMethod?: 'pix' | 'credit_card' | 'pos_paid';
    optionals?: EstimateOptionalType[];
    itinerary?: ItineraryType;
    reservation?: any;
    user?: any;
    allOptionals?: OptionalType[];
  }) => {
    if (data.estimate) {
      setEstimate(data.estimate);
    }

    if (data.depot) {
      setDepot(data.depot);
    }

    if (data.returnDepot) {
      setReturnDepot(data.returnDepot);
    }

    if (data.vehicle) {
      setVehicle(data.vehicle);
    }

    if (data.paymentMethod) {
      setPaymentMethod(data.paymentMethod);
    }

    if (data.optionals) {
      setOptionals(data.optionals);
    }

    if (data.itinerary) {
      setItinerary(data.itinerary);
    }

    if (data.reservation) {
      setReservation(data.reservation);
    }

    if (data.user) {
      setUser(data.user);
    }

    if (data.allOptionals) {
      setAllOptionals(data.allOptionals);
    }
  }, [setEstimate, setDepot, setReturnDepot, setVehicle, setPaymentMethod, setOptionals, setItinerary, setReservation, setUser, setAllOptionals]);

  const reset = () => {
    setRenter(undefined);
    setOptionals(undefined);
    setEstimate(undefined);
    setEstimates({
      pix: {} as Estimate,
      cartao: {} as Estimate,
      pospago: {} as Estimate,
    });
    setDepot(undefined);
    setReturnDepot(undefined);
    setVehicle(undefined);
    setPaymentMethod('credit_card');
    setReservation({});
    setUser({});
  };

  const revalidateEstimate = useCallback(async () => {
    await getEstimates();
  }, [getEstimates]);

  return (
    <Context.Provider
      value={{
        depot,
        returnDepot,
        vehicle,
        itinerary,
        rate,
        optionals,
        setItinerary,
        renter,
        estimate,
        estimates,
        setEstimates,
        isPrePaid,
        paymentMethod,
        setPaymentMethod,
        reservation,
        setReservation,
        user,
        setUser,
        reset,
        cpdid,
        allOptionals,
        setAllOptionals,
        loadReservationData,
        revalidateEstimate,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useReservation() {
  return useContext(Context);
}

const applyAdjustments = (methodEstimate: Estimate): Estimate => {
  if (
    typeof methodEstimate["ATH PAD"] !== "undefined" &&
    methodEstimate["ATH PAD"]?.total > 0
  ) {
    methodEstimate.caucao = methodEstimate["ATH PAD"].total;
    methodEstimate.total = methodEstimate.total - methodEstimate.caucao;
  } else {
    methodEstimate.caucao = 0;
  }

  methodEstimate.total_without_interests =
    methodEstimate?.ADMIN?.total > 0
      ? methodEstimate.total - methodEstimate.ADMIN.total
      : methodEstimate.total;

  return methodEstimate;
};
