import { OptionalType } from '@/types';

export type ParsedOptionalRegex = RegExpExecArray & {
  groups: { code: string; qtty: string };
};

export type EstimateOptionalType = {
  code: string;
  qtty: number;
  optional?: OptionalType;
};

function match(s: string): [string, number] {
  const regex = /(?<qtty>\d+)x(?<code>[a-zA-Z0-9]+)/gi;

  const matches = regex.exec(s) as ParsedOptionalRegex;
  if (matches) {
    return [matches.groups.code, parseInt(matches.groups.qtty, 10)];
  }
  return ['', 0];
}

/**
 * A partir de um array de string {n}X{code} (onde 'n' é a qtty e 'code' o
 * código do opcional), retorna um array de objeto {code: string, qtty: number}.
 *
 * @param opts array de strings no formato {N}X{CODE}
 * @returns EstimateOptionalType[] array de EstimateOptionalType
 */
export function toArray(opts: string[]): EstimateOptionalType[] {
  return opts.map(o => {
    const [code, qtty] = match(o);
    return { code, qtty };
  });
}

export function parseOptionals(
  strings: string | string[],
): Record<string, number> {
  const opts: Record<string, number> = {};

  if (Array.isArray(strings)) {
    strings.forEach(s => {
      const [code, qtty] = match(s);
      if (code && qtty) {
        opts[code] = qtty;
      }
    });
  } else {
    const [code, qtty] = match(strings);
    if (code && qtty) {
      opts[code] = qtty;
    }
  }

  return opts;
}

/**
 * A partir de um objeto {code: qtty, ...} Retorna um array de string {n}X{code}, onde
 * 'n' é a qtty e 'code' o código do opcional.
 *
 * @param opts Record<string, number>
 * @returns string[]
 */
export function formatOptionals(opts: Record<string, number>): string[] {
  return Object.keys(opts).map(code => `${opts[code]}X${code}`);
}

export { };
